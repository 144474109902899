import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import AuthWall from './auth/AuthWall';
import { ConfirmProvider } from 'material-ui-confirm';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#eeeeee'
    }
  }
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider>
        <BrowserRouter>
          <Navbar />
          <AuthWall>
            <Container maxWidth="lg" sx={{ mt: 3, mb: 12 }}>
              <Routes>
                <Route path='/' Component={App} />
              </Routes>
            </Container>
          </AuthWall>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  </React.StrictMode>
);
