// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD1NBwVAygPDZL5zbgA1E9Aic1p9I9egig",
    authDomain: "alvin-tree-service.firebaseapp.com",
    projectId: "alvin-tree-service",
    storageBucket: "alvin-tree-service.appspot.com",
    messagingSenderId: "451772031642",
    appId: "1:451772031642:web:c9ef95578a4de595437523"
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig)

export const FirebaseFirestore = getFirestore(FirebaseApp)
export const FirebaseStorage = getStorage(FirebaseApp)
export const FirebaseAuth = getAuth(FirebaseApp)
