import './App.css';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, Chip, Divider, Fab, Snackbar, Stack, Typography, useTheme } from '@mui/material';
import { Add, ChevronRight, CopyAll, Email, ExpandMore, Person, Phone, Photo, PinDrop, PriorityHigh, Today } from '@mui/icons-material';
import HSpacer from './util/HSpacer';
import If, { IfLet } from './util/If';
import { Job } from './util/Types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import JobDialog from './JobDialog';
import FullscreenProgress from './util/FullscreenProgress';
import useFirestore from './util/useFirestore';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

function JobCard(props: {job: Job, onClick: (arg0: Job) => void}) {
  let theme = useTheme()
  let [copyNotification, setCopyNotification] = useState(false)
  let copyAddress = useCallback(() => {
    let street = [props.job.address.line1, props.job.address.line2].filter(l => l.length > 0).join(' ')
    let cityStateZip = [props.job.address.city, props.job.address.state, props.job.address.zip].filter(l => l.length > 0).join(', ')
    navigator.clipboard.writeText([street, cityStateZip].filter(l => l.length > 0).join(', ')).then(() => setCopyNotification(true))
  }, [props.job, setCopyNotification])
  const hasPhoneNumber = useMemo(() => props.job.phone.length > 0, [props.job])
  const hasEmail = useMemo(() => props.job.email.length > 0, [props.job])
  const hasAddress = useMemo(() => [props.job.address.line1, props.job.address.city, props.job.address.state].every(l => l.length > 0), [props.job])
  return <Card variant='outlined'>
    <CardActionArea onClick={() => props.onClick(props.job)}>
      <CardContent>
        <Stack direction='row'>
          <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <If condition={props.job.priority === true}>
                <PriorityHigh fontSize='small' color='error' />
              </If>
              <Typography variant='h6'>
                <If condition={props.job.customerName.length > 0}>
                  {props.job.customerName}
                </If>
                <If condition={props.job.customerName.length === 0}>
                  <Typography color="text.secondary">Unspecified</Typography>
                </If>
                <If condition={props.job.estimatedPrice.length > 0}><>
                  {bull} {isNaN(parseInt(props.job.estimatedPrice)) ? props.job.estimatedPrice : `$${props.job.estimatedPrice}`}
                </></If>
                <IfLet value={props.job.estimatedHours}>{h => <>
                  {bull} {h}hrs
                </>}</IfLet>
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ ...theme.typography.body2, justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }} useFlexGap divider={<Divider orientation='vertical' variant='fullWidth' flexItem />}>
              {hasAddress && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <PinDrop fontSize='small' />
                <Typography fontSize='inherit'>{props.job.address.city}</Typography>
              </Stack>}
              {props.job.contactName && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Person fontSize='small' />
                <Typography fontSize='inherit'>{props.job.contactName}</Typography>
              </Stack>}
              {props.job.attachments.length > 0 && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Photo fontSize='small' />
                <Typography fontSize='inherit'>{props.job.attachments.length}</Typography>
              </Stack>}
              <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Today fontSize='small' />
                <Typography fontSize='inherit'>{new Date(props.job.createdAt).toLocaleString(undefined, {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true
                })}</Typography>
              </Stack>
            </Stack>
            <If condition={!!props.job.notes}>
              <Typography color='textSecondary' fontStyle='italic'>
                {props.job.notes}
              </Typography>
            </If>
          </Stack>
          <HSpacer />
          <ChevronRight fontSize='medium' sx={{ my: 'auto' }} />
        </Stack>
      </CardContent>
    </CardActionArea>
    <If condition={hasPhoneNumber || hasEmail || hasAddress}>
      <Divider/>
      <CardActions>
        <If condition={hasPhoneNumber}>
          <Button href={`tel:+1${props.job.phone.trim().replaceAll('-', '')}`} size='small' startIcon={<Phone />}>Call</Button>
        </If>
        <If condition={hasEmail}>
          <Button href={`mailto:${props.job.email}`} size='small' startIcon={<Email />}>Email</Button>
        </If>
        <If condition={hasAddress}>
          <Button onClick={copyAddress} size='small' startIcon={<CopyAll />}>Address</Button>
        </If>
      </CardActions>
    </If>
    <Snackbar anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} open={copyNotification} autoHideDuration={5000} onClose={() => setCopyNotification(false)}>
      <Alert color='success' variant='filled'>Address copied to clipboard.</Alert>
    </Snackbar>
  </Card>
}

function JobSection(props: {onSelect: (p0: Job) => void, jobs: Job[], defaultExpanded?: boolean, title: string}) {
  return <Accordion elevation={3} defaultExpanded={props.defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Chip label={props.jobs.length} />
        <Typography variant='h5'>{props.title}</Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Stack direction='column' spacing={1}>
        {props.jobs.map(j => <JobCard key={j.id} job={j} onClick={() => props.onSelect(j)} />)}
        <If condition={props.jobs.length === 0}>
          <Stack direction='row'>
            <HSpacer />
            <Typography variant='subtitle1' fontStyle='italic' color='textSecondary'>No jobs available.</Typography>
            <HSpacer />
          </Stack>
        </If>
      </Stack>
    </AccordionDetails>
  </Accordion>
}

function App() {
  const [currentJob, setCurrentJob] = useState<Job | null>(null)
  const jobs = useFirestore<Job>('jobs')
  useEffect(() => {
    setCurrentJob(current => current && jobs ? jobs.find(j => j.id === current.id) || null : null)
  }, [setCurrentJob, jobs])
  return <>
    <If condition={jobs === null}>
      <FullscreenProgress/>
    </If>
    <IfLet value={jobs as Job[]}>{jobs =>
      <>
        <JobSection title="Active Jobs" jobs={jobs.filter(j => j.active)} defaultExpanded onSelect={setCurrentJob}/>
        <JobSection title="Closed Jobs" jobs={jobs.filter(j => !j.active)} onSelect={setCurrentJob} />
        <Fab sx={{ position: 'fixed', bottom: t => t.spacing(3), right: t => t.spacing(3) }} onClick={() => setCurrentJob({
            id: Date.now().toString(36).toUpperCase(),
            active: true,
            priority: false,
            customerName: '',
            contactName: '',
            phone: '',
            email: '',
            address: {
              line1: '',
              line2: '',
              city: '',
              state: '',
              zip: ''
            },
            attachments: [],
            notes: '',
            estimatedHours: null,
            estimatedPrice: '',
            complexity: 0,
            equipment: [],
            createdAt: Date.now()
          })
        } variant='extended' color='primary'>
          <Add sx={{ mr: 1 }} /> Job
        </Fab>
        <JobDialog job={currentJob} onClose={() => setCurrentJob(null)} />
      </>
    }</IfLet>
  </>
}

export default App;
